<template>
  <!-- 用户号码池页面 -->
  <div class="my-number">
    <!-- <div class="name" v-show="!showType">
        <span @click="showUser()" :style="{cursor:'pointer',fontSize:'15px'}">
          <i class="el-icon-arrow-left">返回</i>
        </span>
      </div> -->
    <!-- 筛选区域 -->
    <div class="screening">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="筛选框" name="1">
          <div class="screening-out">
            <el-form
              :inline="true"
              :model="searchForm"
              class="demo-form-inline"
            >
              <!-- 筛选项 -->
              <el-row>
                <el-col :span="20">
                  <el-row :gutter="12">
                    <el-col :span="6">
                      <el-form-item label="号&emsp;&emsp;码：" prop="">
                        <el-input v-model="searchForm.numbers" size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="省&emsp;&emsp;份：" prop="">
                        <el-input v-model="searchForm.province" size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="城&emsp;&emsp;市：" prop="">
                        <el-input v-model="searchForm.city" size="mini"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="服务商：" prop="">
                        <el-select
                          v-model="searchForm.amountId"
                          placeholder="请选择"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in amount_id"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="号码状态：" prop="">
                        <el-select
                          v-model="searchForm.status"
                          placeholder="请选择"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['正常','禁用']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="计费类型：" prop="">
                        <el-select
                          v-model="searchForm.type"
                          placeholder="请选择"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['单计费','双计费']"
                            :key="index"
                            :label="item"
                            :value="index+1"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="号码行业：" prop="">
                      <el-cascader :props="{ checkStrictly: true }" filterable placeholder="请选择查询的限制行业" clearable
                             style="width: 100%"
                             v-model="searchForm.industryId"
                             
                             :options="filterSearchIndustryList"
                             size="mini"></el-cascader>
                        <!-- <el-select
                          v-model="searchForm.businessType"
                          placeholder="请选择"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['非金融','房产','家装']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select> -->
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="是否录音：" prop="">
                        <el-select
                          v-model="searchForm.record"
                          placeholder="请选择"
                          style="width: 100%"
                          clearable
                          size="mini"
                        >
                          <el-option
                            v-for="(item, index) in ['否', '是']"
                            :key="index"
                            :label="item"
                            :value="index"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                    <el-form-item label="创建日期：">
                    <el-date-picker
                     v-model="valueTime"
                     type="datetimerange"
                     start-placeholder="开始日期"
                     end-placeholder="结束日期"
                     clearable
                     value-format="YYYY-MM-DD HH:mm:ss"
                     size="mini"
                     :default-time="[
                     new Date(2000, 1, 1, 0, 0, 0),
                     new Date(2000, 2, 1, 23, 59, 59),
                     ]"
                        >
                    </el-date-picker>
                    </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="4">
                  <div class="title-buttom">
                    <el-button class="cancel" size="mini" @click="searchTapReset" round>重置</el-button>
                    <el-button type="primary" size="mini" class="confirmAdd" @click="searchTapFun" round>查询</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <!-- 操作区域 -->
    <el-row>
      <el-col :span="12">
        <div class="title-name">号码列表</div>
      </el-col>
      <el-col :span="12">
        <div class="title-button">
          <el-col :span="2">
            <div class="title-button">
              <!-- 购买号码 -->
              <!-- <el-tooltip content="购买号码" placement="top" effect="light" v-if="env">
                <el-button
                  size="mini"
                  class="confirmAdd" 
                  @click="shoppingPopUp()"
                  circle
                ><icon-font type="icon-yijiangoumai" /></el-button>
              </el-tooltip> -->
              <!-- 文件导出 -->
              
              <el-button     type="primary" size="mini" class="confirmAdd" @click="bindFun()" round v-show="showType">批量退网(勾选)</el-button>
              <el-button     type="primary" size="mini" class="confirmAdd" @click="bindFun1()" round v-show="showType">批量退网(输入)</el-button>

              <el-tooltip content="导出号码" placement="top" effect="light">
                <el-button
                  @click="setSearchExport()"
                  icon="el-icon-download"
                  size="mini"
                  circle
                  >
                </el-button>
              </el-tooltip>
              <!-- <el-tooltip content="列表刷新" placement="top" effect="light">
                <el-button
                  class="editor"
                  size="mini"
                  icon="el-icon-refresh-right"
                  @click="searchTapReset()"
                  circle
                ></el-button>
              </el-tooltip> -->
              <div class="ml-10">
            <el-tooltip content="刷新列表" placement="top" effect="light">
              <div class="font-refresh-out flex-nowrap-center-center" @click="searchTapReset">
                <icon-font v-if="loading==false" type="icon-shuaxin" class="font-refresh"/>
                <i v-else class="el-icon-loading loding-color"></i>
              </div>
            </el-tooltip>
          </div>
            <div class="ml-10"  v-show="!showType">
            <el-dropdown>
              <el-button class="button-el" size="medium"  @click="showUser()">
                返回
              </el-button>
              
            </el-dropdown>
          </div>
            </div>
          </el-col>
        </div>
      </el-col>
    </el-row>
    <!-- 数据列表 -->
    <el-row>
      <el-col :span="24">
        <div>
          <el-table
          @selection-change="handleSelectionChange"

            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            size="mini"
            :row-style="{height:'40px'}"
            :cell-style="{padding:'0px'}"
            :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
            :height="tableHeight"
          >
            <!-- <el-table-column label="用户ID" width="90px">
              <template #default="scope">{{
                scope.row.uid == null ? "-" : scope.row.uid
              }}</template>
            </el-table-column> -->
            <el-table-column type="selection" width="55"> </el-table-column>

            <el-table-column 
            width="140"
            label="服务商">
              <template #default="scope">
                <span>{{ amountText(scope.row.number.amountId) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="号码" width="140px">
              <template #default="scope">{{
                scope.row.number == null ? "-" : scope.row.number.number
              }}</template>
            </el-table-column>
            <el-table-column label="省份">
              <template #default="scope">{{
                scope.row.number == null ? "-" : scope.row.number.province
              }}</template>
            </el-table-column>
            <el-table-column label="城市">
              <template #default="scope">{{
                scope.row.number == null ? "-" : scope.row.number.city
              }}</template>
            </el-table-column>
            <el-table-column label="区号">
              <template #default="scope">{{
                scope.row.number == null ? "-" : scope.row.number.areaCode
              }}</template>
            </el-table-column>
            <!-- <el-table-column label="接口类型">
              <template #default="scope">{{
                scope.row.number == null ? "-" : interfaceText(scope.row.number.interfaceId)
              }}</template>
            </el-table-column> -->
            <el-table-column label="号码状态">
              <template #default="scope">
                <span :class="scope.row.number.status == 0?'pass':'unpass'">{{scope.row.number.status===0?'正常':'禁用'}}</span>
              </template>
            </el-table-column>
            <el-table-column label="计费类型">
              <template #default="scope">
                <el-tag size="mini" v-if="scope.row.number.type === 1">单计费</el-tag>
                <el-tag size="mini" type="success" v-else-if="scope.row.number.type === 2">双计费</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="号码行业">
              <template #default="scope">{{
               scope.row.number.industryName == null ? "--" : scope.row.number.industryName
              }}</template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">{{
                scope.row.createTime == null ? "-" : scope.row.createTime
              }}</template>
            </el-table-column>
            <el-table-column label="是否录音">
              <template #default="scope">
                <span :class="scope.row.number.record === 1 ? 'yes-green':'no-red'">{{scope.row.number.record === 1 ? "是" : "否" }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template #default="scope">
                <div >
                  <span class="button-font-color" @click="viewDetails(scope.row)"  v-if="scope.row.isEdit">
                  
                  开关录音
                  </span>
                 
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!-- 页码 -->
    <div class="flex-nowrap-flex-end">
      <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            background
            :page-sizes="[10, 20, 30, 40]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
    </div>
    <!-- 弹窗→购买号码 -->

    <el-dialog title="开关录音" v-model="DialogBatchaddVisible" width="35%"
                 :close-on-click-modal="false" :destroy-on-close="true" >
        <div class="center-body addBlack">
          <div class="header">
            <el-radio-group v-model="batchTelTypeRadio">
              <el-radio label="1">开</el-radio>
              <el-radio label="0">关</el-radio>
            </el-radio-group>
          </div>

 
        </div>
        <template #footer>
          <div>
            <el-button plain @click="handleBatchCancle">取消</el-button>
            <el-button type="primary" @click="handleBatchAdd">添加</el-button>
          </div>
        </template>
      </el-dialog>

    <div class="popup-view">
      <el-form
        label-position="left"
        label-width="100px"
        :model="upform"
        ref="upform"
      >
        <el-dialog
          title="购买号码"
          v-model="centerDialogVisible"
          width="30%"
        >
          <div class="center-body">
            <!-- <el-row :gutter="12">
              <el-col :span="7"> -->
                <el-form-item label="服务商：" prop="">
                  <el-select
                    v-model="upform.amountId"
                    placeholder="请选择"
                    style="width: 100%"
                    @change="changeValues"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in amount_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="接口类型：" prop="">
                  <el-select
                    v-model="upform.interfaceId"
                    placeholder="请选择"
                    style="width: 100%"
                    @change="queryNumber()"
                    clearable
                  >
                    <el-option
                      v-for="(item, index) in interface_id"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              <!-- </el-col>
              <el-col :span="8"> -->
                <el-form-item label="计费类型">
                  <el-radio-group v-model="upform.type" @change="queryNumber()">
                    <el-radio :label="1" style="margin-left:20px">单计费</el-radio>
                    <el-radio :label="2">双计费</el-radio>
                  </el-radio-group>
                </el-form-item>
              <!-- </el-col>
              <el-col :span="8"> -->
                <el-form-item label="查询结果">
                  <p class="height-box">可用号码数：<span style="color:#f00">{{countNumberValue.numberCount}}</span></p>
                  <p class="height-box">月&emsp;租&emsp;费：<span style="color:#f00">{{countNumberValue.monthlyRent}}</span></p>
                  <p class="height-box">通&emsp;话&emsp;费：<span style="color:#f00">{{countNumberValue.callCost}}</span></p>
                </el-form-item>
              <!-- </el-col>
              <el-col :span="7"> -->
                <el-form-item label="城&emsp;&emsp;市：" prop="">
                  <el-cascader
                    v-model="upform.city"
                    :options="cascadeArray"
                    :props="{label:'name',children:'citys',value:'name'}"
                    @change="handleChange()"
                  ></el-cascader>
                </el-form-item>
              <!-- </el-col>
              <el-col :span="8"> -->
                <el-form-item label="可用数量">
                  <span style="margin-left:20px;color:#f00">{{keyongNumber}}</span>
                </el-form-item>
              <!-- </el-col>
              <el-col :span="8"> -->
                <el-form-item label="采购数量：" prop="">
                  <el-input v-model="upform.quantity" @change="shoppingNumber()"></el-input>
                </el-form-item>
              <!-- </el-col>
            </el-row> -->
          </div>
          <template #footer>
            <el-form-item>
              <el-button
                class="cancel"
                plain
                @click="centerDialogVisible = false"
                round
                >取消</el-button
              >
              <el-button
                class="confirmAdd"
                @click="addSubmit()"
                round
                >确认购买</el-button
              >
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>

        <!-- 弹窗→回收小号 -->
    <div class="pop-view">
      <el-form label-position="top" label-width="100px" :model="bind_form" ref="bind_form">
        <el-dialog :title="recyclingType==1?'回收小号':'退网小号'" :before-close="bfClose" v-model="centerBranchVisibleOther"
          :close-on-click-modal="false" :destroy-on-close="true" width="30%">
          <div class="center-body">
            <el-row :gutter="12">
              <el-col :span="recyclingType==1?8:24" :push="recyclingType==1?2:0">
                <!-- <el-form-item
                  :label="'小号：' + multipleSelection.length + '个'"
                >
                  <div class="showIdsList">
                    <div v-if="multipleSelection.length == 0">请勾选小号</div>
                    <div v-for="item in multipleSelection" :key="item.id">
                      {{ item.number }}
                    </div>
                  </div>
                </el-form-item> -->
                <el-input type="textarea" placeholder="请输入小号，每行一个小号" v-model="inputMobiles" :rows="12"></el-input>
                <div v-if="recyclingType==2" style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;">
                  需要退网的号码必须完成回收操作，正在使用的号码无法强行退网</div>
                <div style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;" class="noticetext"
                  v-html="recycleNumbers"></div>
                <div style="font-size:14px;color:#aaa;margin-top:5px;margin-left:5px;" class="noticetext"
                  v-html="existNumbers"></div>
              </el-col>
              <el-col :span="1" v-if="recyclingType==1" :push="2">
                <el-button size="small" plain @click="search">搜索<i class="el-icon-arrow-right el-icon--right"></i>
                </el-button>
              </el-col>
              <el-col :span="10" v-if="recyclingType==1" :push="5">
                <!-- <el-form-item label="用户：">
                  <el-select v-model="uids" filterable multiple placeholder="请选择用户">
                    <el-option
                      v-for="(item, index) in user_list"
                      :key="index"
                      :label="item.nickname"
                      :value="item.uid"
                    >
                      <div class="flex-nowrap-space-between">
                        <div :class="item.status == 1?'no-red':''">
                          <span>{{ item.nickname }}</span>
                          <span class="ml-5">(Id:{{ item.uid }})</span>
                        </div>
                        <div v-if="item.status == 1" class="no-red">禁</div>
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
                </el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group class="checkbox" v-model="checkedPerson" @change="handleCheckedCitiesChange">
                  <el-checkbox v-for="item in persons" :label="item.uid" :key="item.uid">
                    {{item.nickname}}--{{item.company}}--(ID:{{item.uid}})</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>
          </div>
          <template #footer>
            <el-form-item>
              <el-button class="cancel" plain @click="bfClose(false)" round>取消</el-button>
              <el-button class="confirmAdd" @click="backNum()" round>{{recyclingType==1?'回收':'退网'}}</el-button>
            </el-form-item>
          </template>
        </el-dialog>
      </el-form>
    </div>
  </div>
</template>
<script>
  import bind from '@/api/open/privacy/bind'
  import {  message, Modal, notification } from 'ant-design-vue';

import number from "@/api/open/privacy/number";
import unumberts from "@/api/open/privacy/userNumber"
import interfacets from '@/api/open/privacy/interface'
import amountts from '@/api/open/privacy/amount'
import { IconFont } from "@/utils/iconfont"
import dictionary from '@/api/system/dictionary'
export default {
  name: "MyNumber",
  components: { 
    IconFont
  },
  props: {
    uid: {
      type: Number,
      default: '',
    },
     showType: {
      type: Boolean,
      default: () => true
    },
  },
  computed:{
     filterSearchIndustryList() {
      //选择服务商后，只展示当下服务商行业、系统默认（amountId==0）和全行业（amountId==0）
      let res = [];
      res = this.industryTreeList?.filter(industry => {
        return industry.amountId == 0 || industry.amountId == this.searchForm.amountId;
      })
      return res || [];
    },
  },
  data() {
    return {
      valueTime: [], //选中时间段
      defaultTime: [new Date(2000, 1, 1), new Date(2000, 2, 1)], //默认时间 '12:00:00', '08:00:00'
      mynumber:null,
      DialogBatchaddVisible:false,
      batchTelTypeRadio:null,
      inputMobiles:'',
      centerBranchVisibleOther:false,
      recyclingType:2,
      allIds:[],
      env: process.env.NODE_ENV === 'development',
      tableData: [], //数据列表
      centerDialogVisible: false, //弹窗--添加号码
      activeNames: [], //折叠面板
      uploadData: {}, // 导入文件携带数据
      tableHeight: "", //表格高度
      operationType: 0, //弹窗提交按钮文字状态
      currentPage: 1, //分页
      total: 0, //总数据条数
      interface_id: [], // 拿到的接口类型
      amount_id: [], // 拿到的通信账户类型
      cascadeArray:[], // 级联数组
      countNumberValue:{}, // 接口返回值--可用号码数、月租、通话费
      keyongNumber:'',  // 可用号码数量
      upform: {
        //弹窗数组
        number: "", // 号码
        province: "", // 省份
        city: "", // 城市
        areaCode: "", // 区号
        interfaceId: "", // 接口类型
        amountId: "", // 通信账户ID
        status: "", // 号码状态(0:正常/1:禁用)
        type: "", // 小号类型(1:单计费/2:双计费)
        industryId: "", // 号码行业(0:非金融/1:房产/2:家装)
        record: "", // 是否录音
        distributionCount:'', // 分配次数
        quantity:'',  // 用户购买数量
      },
      importArray: {
        // 导入文件的存储对象
        amountId: "",
        industryId: "",
        fileUrl: "",
        interfaceId: "",
        record: "",
      },
      industryTreeList:[],
      searchForm: {
        //搜索项、数据存储
        page:1,
        pageSize:20,
        number: "",
        province: "",
        city: "",
        areaCode: "",
        interfaceId: "",
        amountId: "",
        status: "",
        type: "",
        industryId: "",
        record: "",
        distributionCount:'',
        createTimeBegin:'',
        createTimeEnd:'',
        uid:this.uid?this.uid:''
      },
      loading: true, // loding 状态启用
    };
  },
  mounted(){
           dictionary.industry().then((res) => {
      //     console.log(res.data, '99999999999999999999999999999999999========================================')
      let arr = []
      this.industryTreeList = this.getIndustryTree(res.data, arr)
   
    })
  },
  methods: {
       getIndustryTree(data, returnData) {
      if (!data) return returnData;
      if (Array.isArray(data)) {
        data.forEach((v, i) => {
          if (v?.children && v.children.length > 0) {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
              children: [],
            })
          } else {
            returnData?.push({
              amountId: v.amountId,//线路
              label: v.name,
              value: v.id,
            })
          }

          if (v.children) {
            this.getIndustryTree(v.children, returnData?.[i]?.children)
          }
        })
      }
      return returnData
      // console.log(returnData, 'returnDatareturnDatareturnData')
    },
    showUser(){

        this.$emit('userVisibilityChanged');
    },
    viewDetails(e){
this.DialogBatchaddVisible=true
this.batchTelTypeRadio=e.number.record+''
this.mynumber=e.number.number

    },
    handleBatchAdd(){
number.editRecord({numbers:this.mynumber,
  record:this.batchTelTypeRadio
}).then(res=>{

if(res.code ==200){
  this.getDataList()
  message.success('操作成功');
  this.DialogBatchaddVisible=false

}


})
    },
    handleBatchCancle(){
      this.DialogBatchaddVisible=false

    },
bfClose(){
this.centerBranchVisibleOther=false
this.inputMobiles=''

},
bindFun1(){

this.centerBranchVisibleOther=true
},

 backNum() {
      let arr = this.inputMobiles.split(/\r\n|\r|\n/)
      arr = [...new Set(arr)]
      this.inputMobiles = arr.join('\n')
      if (!this.inputMobiles) {
        return this.$notify.warning('请输入小号')
      }
      
        number.auditPLus(arr).then((res) => {
                    //  message.success(res.data);
this.$notify.success(res.data)
this.centerBranchVisibleOther=false
this.inputMobiles=''

        //  if (res.code == 200) {
                   ///  message.success('操作成功');
// this.$notify.warning('请')
      //    }
        })
      
    },
     bindFun (type,id){
  
       if(this.allIds.length===0){
        // eslint-disable-next-line no-undef
        notification['error']({
        message: '请选择',
        description:  '',
      });
        return
       }
       let ids = {
        ids:this.allIds
       }
        this.$confirm('确认退网?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           bind.numberRecover({...ids}).then(e=>{
          this.getDataList();
          this.$message.success('操作成功');
         })
        }).catch(() => {
              
              
              
        });
      // Modal.confirm({
      //   centered:true,
      //   title: '确认退网?',
      //   okText: '确认',
      //   cancelText:'取消',
      //   onOk() {
       
      //    bind.numberRecover({...ids}).then(e=>{
      //     this.getDataList();

      //     message.success('操作成功');

      //    })
      //   },
      //   // eslint-disable-next-line @typescript-eslint/no-empty-function
      //   onCancel() {},
      // });
    } ,




    handleSelectionChange(val) {
      let newIds = [];
      val.forEach((item) => {
        newIds.push(item.id);
      });
      this.allIds = newIds;
    },
    // 选中服务商
    changeValues(val){
      interfacets.getInterface({
        amountId:val
      }).then((res) => {
        this.interface_id = res.data
      });
    },
    // 采购数量
    shoppingNumber(){
      if(isNaN(this.upform.quantity)){
        this.upform.quantity = 0;
      }else{
        if(this.upform.quantity>this.keyongNumber){
          this.upform.quantity = this.keyongNumber;
        }else if(this.upform.quantity<0){
          this.upform.quantity = 0;
        }
      }
    },
    // 查询可用号码数/省市级联号码数
    queryNumber(){
      if(this.upform.interfaceId&&this.upform.type){
        let arr = {
          interfaceId:this.upform.interfaceId,
          type:this.upform.type,
        }
        number.countNumber({...arr}).then(res=>{
          if(res.code===200){
            this.countNumberValue = res.data
          }
        })
        number.cityNumber({...arr}).then(res=>{
          if(res.code===200){
            this.cascadeArray = res.data;
          }
        })
      }
    },
    // 购买号码--确认
    addSubmit() {
      if(this.upform.quantity>0){
        let str = this.upform.city;
        let arr = {
          province:str[0],
          city:str[1],
          interfaceId:this.upform.interfaceId,
          type:this.upform.type,
          quantity:this.upform.quantity,
        }
        unumberts.numberShop({...arr}).then(res =>{
          if(res.code===200){
            message.success('添加成功');
            this.centerDialogVisible = false;
            this.searchTapReset()
          }
        })
      }
    },
    // 接口类型数字转文字
    interfaceText(id){
      for(let i of this.interface_id){
        if(id===i.id){
          return i.name
        }
      }
    },
    // 通信账户汉字
    amountText(id) {
      if (id == 0) {
        return "主账号";
      } else {
        for (let i of this.amount_id) {
          if (i.id === id) {
            return i.name;
          }
        }
      }
    },
    // 购买号码--点击
    shoppingPopUp(){
      this.centerDialogVisible = true;
    },
    // 重置搜索项
    searchTapReset(){
      this.searchForm = {
        page: this.currentPage,
        pageSize: 20,
        uid:this.uid?this.uid:''
      };
      this.valueTime=[]
      this.getDataList();
    },
    // 搜索特殊项
    searchTapFun(){
      this.getDataList();
    },
    // 文件导出
    setSearchExport(){
      message.warning('正在导出，请在消息模块下载');
      number.exportNumber({...this.searchForm}).then(res=>{
        if(res.code!=200){
          message.error(res.message);
        }
      })
    },
    // 获取接口类型数组
    getApiType() {
      interfacets.seeApiList({}).then((res) => {
        this.interface_id = res.data;
      });
    },
    // 获取通信账户类型
    getAmountType() {
      amountts.getAmountList({}).then((res) => {
        this.amount_id = res.data;
      });
    },
    // 级联选择--循环取值
    handleChange() {
      let city = this.upform.city;
      for(let i of this.cascadeArray){
        if(i.name === city[0]){
          for(let j of i.citys){
            if(j.name===city[1]){
              this.keyongNumber = j.count;
            }
          }
        }
      }
    },
    // 获取用户小号列表
    getDataList() {
      this.searchForm.industryId =  this.searchForm.industryId ? Array.isArray( this.searchForm.industryId) ?  this.searchForm.industryId[ this.searchForm.industryId.length - 1] :  this.searchForm.industryId : null;//单选返回是一维数组
      if (this.valueTime) {
        this.searchForm.createTimeBegin = this.valueTime[0];
        this.searchForm.createTimeEnd = this.valueTime[1];
      } else {
        this.searchForm.createTimeBegin = "";
        this.searchForm.createTimeEnd = "";
      }
      this.loading = true;
      unumberts.getNumberList({...this.searchForm}).then(res =>{
        this.tableData = res.data.records;
        this.loading = false;
        this.total = res.data.total;
      })
    },
    // 分页--条数
    handleSizeChange(val) {
        this.searchForm.pageSize = val;
        this.getDataList()
    },
    // 分页--页码
    handleCurrentChange(val) {
        this.searchForm.page = val;
        this.currentPage = val;
        this.getDataList()
    },
  },
  created() {
    this.getDataList();
    this.getApiType();
    this.getAmountType();
    this.tableHeight = this.$store.state.tableHeight;
  },
};
</script>
<style lang="scss" scoped>
.my-number {
  height: calc(100vh - 135px);
  overflow: overlay;
  padding-right: 1.1rem;
  box-sizing: content-box;
  .title-name {
    margin-bottom: 1rem;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #303133;
  }
  .title-button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1rem;
    // background-color:orange;
  }
  .screening {
    margin-bottom: 0.9375rem;
    .screening-out {
      margin: 0 0.9375rem;
    }
    .title-button {
      padding-right: 0;
    }
  }
  .height-box{
    height: 20px;
  }
}
.title-buttom{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
}
.cancel{
    color: #637DFF;
    border: #637DFF solid 1px;
}
.confirmAdd{
    background: #637DFF;
    color: #fff;
}
.pass{
    color: #67C23A;
}
.unpass{
    color: #F56C6C;
}
</style>